import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as add_45articlezLQ007XEWlMeta } from "/vercel/path0/pages/articleManager/add-article.vue?macro=true";
import { default as add_45series0DxbeumyFoMeta } from "/vercel/path0/pages/articleManager/add-series.vue?macro=true";
import { default as edit_45articleNidtSFSdMyMeta } from "/vercel/path0/pages/articleManager/edit-article.vue?macro=true";
import { default as edit_45series83Nryy42ZbMeta } from "/vercel/path0/pages/articleManager/edit-series.vue?macro=true";
import { default as index1yYgELm7T2Meta } from "/vercel/path0/pages/articleManager/index.vue?macro=true";
import { default as logincvMirA58w3Meta } from "/vercel/path0/pages/articleManager/login.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91name_93svhKuWJwIOMeta } from "/vercel/path0/pages/posts/[series]/[name].vue?macro=true";
import { default as indextlygJcBteWMeta } from "/vercel/path0/pages/posts/[series]/index.vue?macro=true";
import { default as indexG7XwsIruPAMeta } from "/vercel/path0/pages/posts/index.vue?macro=true";
import { default as searchv5alMmEgIjMeta } from "/vercel/path0/pages/posts/search.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "articleManager-add-article",
    path: "/articleManager/add-article",
    component: () => import("/vercel/path0/pages/articleManager/add-article.vue").then(m => m.default || m)
  },
  {
    name: "articleManager-add-series",
    path: "/articleManager/add-series",
    component: () => import("/vercel/path0/pages/articleManager/add-series.vue").then(m => m.default || m)
  },
  {
    name: "articleManager-edit-article",
    path: "/articleManager/edit-article",
    component: () => import("/vercel/path0/pages/articleManager/edit-article.vue").then(m => m.default || m)
  },
  {
    name: "articleManager-edit-series",
    path: "/articleManager/edit-series",
    component: () => import("/vercel/path0/pages/articleManager/edit-series.vue").then(m => m.default || m)
  },
  {
    name: "articleManager",
    path: "/articleManager",
    component: () => import("/vercel/path0/pages/articleManager/index.vue").then(m => m.default || m)
  },
  {
    name: "articleManager-login",
    path: "/articleManager/login",
    component: () => import("/vercel/path0/pages/articleManager/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-series-name",
    path: "/posts/:series()/:name()",
    meta: _91name_93svhKuWJwIOMeta || {},
    component: () => import("/vercel/path0/pages/posts/[series]/[name].vue").then(m => m.default || m)
  },
  {
    name: "posts-series",
    path: "/posts/:series()",
    component: () => import("/vercel/path0/pages/posts/[series]/index.vue").then(m => m.default || m)
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/vercel/path0/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-search",
    path: "/posts/search",
    component: () => import("/vercel/path0/pages/posts/search.vue").then(m => m.default || m)
  }
]